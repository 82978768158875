<template>
  <div>
    <div class="mb-2">
      <a-button @click="$router.back()" class="w-auto">
        Regresar
      </a-button>
    </div>
    <div class="flex pb-4">
      <div class="w-full">
        <form
          @submit.prevent="isUpdating ? update(period) : store(period)"
          @keydown="removeError($event.target.id)"
          @input="removeError($event.target.id)">
          <h3 class="my-4 text-2xl">
            {{ isUpdating ? 'Actualizar' : 'Crear' }} período
          </h3>
          <div class="flex flex-wrap -mx-2 border-b-2 border-gray-400">
            <div class="w-full md:w-1/2 lg:w-1/2 px-2">
                <a-input
                  label="Año"
                  id="year"
                  class="mb-4"
                  :error="error('year')"
                  v-model.number="period.year" />

                <a-input
                  :disabled="isUpdating"
                  label="Número"
                  class="mb-4"
                  required
                  id="number"
                  :error="error('number')"
                  v-model="period.number" />

                <a-input
                  label="Semanas"
                  class="mb-4"
                  required
                  id="weeks_length"
                  :error="error('weeks_length')"
                  v-model="period.weeks_length" />

                <a-input
                  label="Fecha de inicio"
                  required
                  class="mb-4"
                  id="start_date"
                  type="date"
                  :error="error('start_date')"
                  v-model="period.start_date" />

                <a-input
                  label="Fecha de cierre"
                  required
                  class="mb-4"
                  id="end_date"
                  type="date"
                  :error="error('end_date')"
                  v-model="period.end_date" />

                <a-input
                  label="Fecha de inicio del primer corte"
                  class="mb-4"
                  required
                  id="first_cut_start_date"
                  type="date"
                  :error="error('first_cut_start_date')"
                  v-model="period.first_cut_start_date" />

                <a-input
                  label="Fecha de cierre del primer corte"
                  class="mb-4"
                  required
                  id="first_cut_end_date"
                  type="date"
                  :error="error('first_cut_end_date')"
                  v-model="period.first_cut_end_date" />

                <a-input
                  required
                  label="Fecha inicio del segundo corte"
                  class="mb-4"
                  id="second_cut_start_date"
                  type="date"
                  :error="error('second_cut_start_date')"
                  v-model="period.second_cut_start_date" />

                <a-input
                  required
                  label="Fecha cierre del segundo corte"
                  class="mb-4"
                  id="second_cut_end_date"
                  type="date"
                  :error="error('second_cut_end_date')"
                  v-model="period.second_cut_end_date" />

                <a-select
                  required
                  label="Turno"
                  class="mb-4"
                  :source="scheduleShifts"
                  multiple
                  :close-on-select="false"
                  :loading="loading"
                  v-model="period.settings.schedule_shifts" />

            </div>
            <div class="w-full md:w-1/2 lg:w-1/2 px-2">
              <a-input
                label="Director"
                required
                class="mb-4"
                id="director"
                :error="error('staff.director', { attribute: 'director' })"
                v-model="period.staff.director" />

              <a-input
                label="Cédula del Director"
                required
                class="mb-4"
                id="director_dni"
                :error="error('staff.director_dni', { attribute: 'cedula director' })"
                v-model="period.staff.director_dni" />

              <a-input
                label="Sub-Director"
                required
                class="mb-4"
                id="vice_director"
                :error="error('staff.vice_director', { attribute: 'sub director' })"
                v-model="period.staff.vice_director" />

              <a-input
                label="Director Ejecutivo"
                required
                class="mb-4"
                id="executive_director"
                :error="error('staff.executive_director', { attribute: 'director ejecutivo' })"
                v-model="period.staff.executive_director" />

              <a-input
                label="Director Administrativo"
                required
                class="mb-4"
                id="administrative_director"
                :error="error('staff.administrative_director', { attribute: 'director administrativo' })"
                v-model="period.staff.administrative_director" />

              <a-input
                label="Coordinador Académico"
                required
                class="mb-4"
                id="academic_coordinator"
                :error="error('staff.academic_coordinator', { attribute: 'coordinador academico' })"
                v-model="period.staff.academic_coordinator" />

              <a-input
                label="Responsable de Control de Estudios"
                required
                class="mb-4"
                id="study_control"
                :error="error('staff.study_control', { attribute: 'responsable de control de estudio' })"
                v-model="period.staff.study_control" />

              <a-input
                label="Responsable de Firma"
                required
                class="mb-4"
                id="reports_signer"
                :error="error('staff.reports_signer', { attribute: 'responsable de Firma' })"
                v-model="period.staff.reports_signer" />

<!--              <a-input-->
<!--                label="Modelo de arte"-->
<!--                required-->
<!--                class="mb-4"-->
<!--                id="art_model_name"-->
<!--                :error="error('art_model.name')"-->
<!--                v-model="period.art_model.name" />-->

              <a-input
                label="Precio del semestre (USD)"
                required
                class="mb-4"
                id="semester_price_e2"
                :error="error('staff.semester_price_e2', { attribute: 'precio del semestre' })"
                v-model.number="period.semester_price_e2" />

              <a-input
                label="Fecha del primer corte para notificar que no se han cargado notas"
                required
                type="date"
                class="mb-4"
                id="pending_evaluations_fc_reminder_date"
                :error="error('pending_evaluations_fc_reminder_date', { attribute: 'fecha máxima del primer corte' })"
                v-model="period.pending_evaluations_fc_reminder_date" />

              <a-input
                label="Fecha del segundo corte para notificar que no se han cargado notas"
                required
                type="date"
                class="mb-4"
                id="pending_evaluations_sc_reminder_date"
                :error="error('pending_evaluations_sc_reminder_date', { attribute: 'fecha máxima del segundo corte' })"
                v-model="period.pending_evaluations_sc_reminder_date" />

              <div class="grid grid-cols-2">
                <template v-if="!isUpdating">
                  <div>
                    <label for="enabled_inscriptions" class="block text-gray-700 font-medium">¿Habilitar inscripciones?</label>
                    <a-switch id="enabled_inscriptions" class="mb-4 w-full" v-model="period.enabled_inscriptions" />
                  </div>
                </template>

                <template v-if="isUpdating">
                  <div>
                    <label class="block mb-2 text-gray-700 font-medium" for="enabled_preregisters">¿Habilitar preinscripciones?</label>
                    <a-switch class="mb-4" id="enabled_preregisters" @input="handle" v-model="period.enabled_preregisters" />
                  </div>
                </template>

                <div>
                  <label class="block mb-2 text-gray-700 font-medium" for="polls">¿Habilitar encuestas?</label>
                  <a-switch class="mb-4" id="polls" v-model="period.polls" />
                </div>

                <template v-if="isUpdating">
                  <div>
                    <label class="block mb-2 text-gray-700 font-medium" for="enabled">¿Habilitar período?</label>
                    <a-switch class="mb-4" id="enabled" v-model="period.enabled" />
                  </div>
                </template>
              </div>
            </div>
          </div>
<!--          Notas y evaluaciones -->
          <h3 class="my-4 text-2xl">Notas y evaluaciones</h3>
          <div class="flex flex-wrap -mx-2">
            <div class="w-full md:w-1/2 lg:w-1/2 px-2">
              <a-input
                label="Mínimo promedio aprobatorio"
                required
                class="mb-4"
                id="approval_minimum_avg"
                type="number"
                :min="0"
                :error="error('approval_minimum_avg')"
                v-model.number="period.approval_minimum_avg" />

              <a-input
                label="Nota máxima"
                required
                class="mb-4"
                id="max_note"
                type="number"
                :min="0"
                :error="error('max_note')"
                v-model.number="period.max_note" />

              <a-input
                label="Mínima nota aprobatoria"
                required
                class="mb-4"
                id="approval_minimum_note"
                type="number"
                :min="0"
                :error="error('approval_minimum_note')"
                v-model.number="period.approval_minimum_note" />

              <a-input
                label="Nota de alto rendimiento"
                class="mb-4"
                requried
                id="high_performance_note"
                type="number"
                :min="0"
                :error="error('high_performance_note')"
                v-model.number="period.high_performance_note" />

              <a-input
                label="Nota de bajo rendimiento"
                class="mb-4"
                requried
                id="low_performance_note"
                type="number"
                :min="0"
                :error="error('low_performance_note')"
                v-model.number="period.low_performance_note" />

              <a-input
                label="Limite de inasistencias para reprobar"
                required
                class="mb-4"
                id="inasistence_limit_to_repove"
                type="number"
                :min="0"
                :error="error('max_absences_to_fail')"
                v-model.number="period.max_absences_to_fail" />

              <a-input
                label="Mínimo de clases evaluadas por corte"
                required
                class="mb-4"
                id="minimal_evaluated_class_per_cut"
                type="number"
                :min="1"
                v-model.number="period.min_evaluated_lessons" />

            </div>
            <div class="w-full md:w-1/2 lg:w-1/2 px-2">
<!--              <label class="block mb-2 text-gray-700 font-medium" for="final_delivery">¿Tiene entrega final?</label>-->
<!--              <a-switch class="mb-4" id="final_delivery" v-model="period.final_delivery" />-->
              <a-input
                label="Porcentaje de evaluación del primer corte"
                required
                class="mb-4 border-red-400"
                id="first_cut_evaluation_porcent"
                type="number"
                :min="1"
                v-model.number="period.first_cut_evaluation_weighting_prc" />

              <a-input
                label="Porcentaje de evaluación del segundo corte"
                required
                class="mb-4"
                id="second_cut_evaluation_porcent"
                type="number"
                :min="1"
                v-model.number="period.second_cut_evaluation_weighting_prc" />

              <h2 v-if="!prcSum" class="mb-4 text-red-400">La suma de los porcentajes del primer y segundo corte debe ser igual a 100</h2>

              <a-input
                label="Porcentaje de evaluación del primer corte con entrega final"
                required
                class="mb-4 border-t-2 pt-4 border-gray-400"
                id="first_cut_evaluation_porcent"
                type="number"
                :min="1"
                v-model.number="period.first_cut_evaluation_weighting_prc_with_finals" />

              <a-input
                label="Porcentaje de evaluación del segundo corte con entrega final"
                required
                class="mb-4"
                id="first_cut_evaluation_porcent"
                type="number"
                :min="1"
                v-model.number="period.second_cut_evaluation_weighting_prc_with_finals" />

              <a-input
                label="Porcentaje de evaluación de entrega final"
                required
                class="mb-4"
                id="final_delivery_evaluation_weighting_prc"
                type="number"
                :min="1"
                :error="error('final_delivery_evaluation_weighting_prc')"
                v-model.number="period.final_delivery_evaluation_weighting_prc" />
              <h2 v-if="!prcSumFinalDelivery" class="text-red-400">La suma de los porcentajes del primer y segundo corte con entregas finales, y la entrega final debe ser igual a 100</h2>

              <a-input
                label="Fecha de entregas finales"
                type="date"
                class="mb-4"
                id="final_delivery_days"
                :error="error('final_delivery_days', { attribute: 'fecha de entrega final' })"
                v-model="final_delivery_day" />

              <div>
                <!--                <p >{{ final_date }}</p>-->
                <div class="inline-block" v-for="final_date in period.final_delivery_days" :key="final_date">
                  <a-badge class="mx-1 mb-4">
                    <p class="inline-block align-middle">{{ final_date }}</p>
                    <p class="inline-block text-red-400 ml-2 font-bold text-lg cursor-pointer" @click="removeDate(final_date)">x</p>
                  </a-badge>
                </div>
              </div>

              <a-input
                  label="Fecha de bloqueo de notas del Primer Corte"
                  type="date"
                  class="mb-4 border-t-2 pt-4 border-gray-400"
                  id="final_delivery_days"
                  v-model="period.settings.first_cut_note_freeze_date" />

              <a-input
                  label="Fecha de bloqueo de notas del Segundo Corte"
                  type="date"
                  class="mb-4"
                  id="final_delivery_days"
                  v-model="period.settings.second_cut_note_freeze_date" />
            </div>
          </div>

          <div class="flex space-x-2">
            <a-button mode="primary" type="submit" :loading="loading" :disabled="!prcSum || !prcSumFinalDelivery">
              {{ isUpdating ? 'Actualizar cambios' : 'Guardar cambios' }}
            </a-button>

            <a-button outlined class="w-auto" :to="{ name: 'periods' }">
              Cancelar
            </a-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// TODO FECHAS DE ENTREGAS FINALES: podria hacerlo tipo tags, que al seleccionar se agreguen en forma de tag y pueda eliminar la fecha conc clicks, o así
// TODO Tambien falta agregar las fechas finales al "show" de periodo
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import moment from '@/filters/moment'
export default {
  data: () => ({
    period: {
      art_model: {
        name: ''
      },
      year: 0,
      number: 0,
      weeks_length: 0,
      start_date: '',
      end_date: '',
      // first_half_end_date: new Date(),
      // second_cut_end_date: new Date(),
      approval_minimum_note: 0,
      approval_minimum_avg: 0,
      high_performance_note: 0,
      low_performance_note: 0,
      max_absences_to_fail: 0,
      enabled_inscriptions: false,
      polls: false,
      final_delivery: true,
      final_delivery_days: [],
      enabled_preregisters: false,
      enabled: false,
      max_note: 20,
      first_cut_evaluation_weighting_prc: 1,
      second_cut_evaluation_weighting_prc: 1,
      first_cut_evaluation_weighting_prc_with_finals: 1,
      second_cut_evaluation_weighting_prc_with_finals: 1,
      final_delivery_evaluation_weighting_prc: 1,
      min_evaluated_lessons: 1,
      settings: {
        schedule_shifts: [],
        first_cut_note_freeze_date: '',
        second_cut_note_freeze_date: '',
      },
      staff: {                        // Configuración de personal
        director: '',                 // Director
        director_dni: '',              // Cedula director
        vice_director: '',            // Sub director
        executive_director: '',       // Director ejecutivo
        administrative_director: '',  // Director administrativo
        academic_coordinator: '',     // Coordinador academico
        study_control: '',            // Responsable de control de estudio
        reports_signer: ''            // Firmante de reportes/documentos
      }
    },
    final_delivery_day: '',
    final_delivery_days: [],
    isUpdating: false
  }),
  watch: {
    // 'period.final_delivery'(value) {
    //   console.log(value)
    //   this.period.final_delivery_evaluation_weighting_prc = 0
    // },
    'final_delivery_day'(value) {
      if (this.period.final_delivery_days === null) {
        this.period.final_delivery_days = []
        this.period.final_delivery_days.push(value)
      } else {
        if (!this.period.final_delivery_days.includes(value)) {
          this.period.final_delivery_days.push(value)
        }
      }
    },
    'period.min_evaluated_lessons'(value) {
      if (value > 1) {
        this.period.min_evaluated_lessons = value
      }
    }
  },
  computed: {
    ...mapGetters({
      hasError: 'hasError',
      error: 'error',
      lang: 'lang'
    }),
    ...mapState({
      periods: state => state.periods.pagination.data,
      currentPeriodOnState: state => state.periods.period,
      loading: state => state.periods.loading
    }),
    prcSum () {
      let sum = this.period.first_cut_evaluation_weighting_prc + this.period.second_cut_evaluation_weighting_prc
        return sum === 100
    },
    prcSumFinalDelivery () {
      let sum = this.period.first_cut_evaluation_weighting_prc_with_finals + this.period.second_cut_evaluation_weighting_prc_with_finals + this.period.final_delivery_evaluation_weighting_prc
        return sum === 100 && (this.period.final_delivery_evaluation_weighting_prc !== 0)
    },
    scheduleShifts() {
      return [
        { label: 'Mañana', value: 'morning' },
        { label: 'Tarde', value: 'afternoon' },
        { label: 'Mixto', value: 'mixed' },
      ]
    }
  },
  methods: {
    ...mapMutations({
      setPeriod: 'periods/SET_PERIOD'
    }),
    ...mapActions({
      fetchPeriod: 'periods/show',
      store: 'periods/store',
      update: 'periods/update',
      removeError: 'removeError',
      openInscriptionsFromProfile: 'periods/openInscriptionsFromProfile'
    }),
    removeDate (date) {
      // this.period.final_delivery_days = this.period.final_delivery_days.indexOf(value => {
      //   if (value === date){
      //     this.period.final_delivery_days.pop()
      //   }
      // })
      this.period.final_delivery_days = this.period.final_delivery_days.filter(it => it !== date)
      // this.period.final_delivery_days = this.period.final_delivery_days.splice(this.period.final_delivery_days.indexOf(value => value === date, 1))
      // console.log(this.period.final_delivery_days)
    },
    moment: () => moment(),
    handleFileSelection(event) {
      this.period.scheduled_content_resource = event.target.files[0]
      this.removeError(event.target.id)
    },
    handle(e) {
      this.openInscriptionsFromProfile({
        ...this.period,
        enabled_inscriptions: this.period.enabled_inscriptions,
        enabled_preregisters: e
      })
    }
  },
  mounted() {
    const id = this.$route.query.update

    if (id) {
      this.isUpdating = true

      this.fetchPeriod({ id,
        // query: { with: 'artModel' }
      })
        .then(period => {
          this.period = {
            ...period,
            semester_price_e2: period.semester_price_e2 / 100,
            pending_evaluations_fc_reminder_date: this.$options.filters.date(period.pending_evaluations_fc_reminder_date, 'yyyy-MM-DD'),
            pending_evaluations_sc_reminder_date: this.$options.filters.date(period.pending_evaluations_sc_reminder_date, 'yyyy-MM-DD'),
            settings: {
              ...period.settings,
              // schedule_shifts: period.settings.schedule_shifts.map($0 => $0)
            }
          }
        })
    }
  }
}
</script>
